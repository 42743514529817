import { getCookieConsent, setCookieConsent } from '@/services/cookie';
import { IonContent, IonPage, IonToggle, IonCard, IonHeader, IonToolbar, IonButton, IonButtons, IonCardHeader, IonCardTitle, IonCardContent, IonTitle, } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
    components: {
        IonContent,
        IonPage,
        IonToggle,
        IonCard,
        IonHeader,
        IonToolbar,
        IonButton,
        IonButtons,
        IonCardHeader,
        IonCardTitle,
        IonCardContent,
        IonTitle,
    },
    setup() {
        var _a;
        const route = useRoute();
        const router = useRouter();
        const consent = (_a = getCookieConsent()) !== null && _a !== void 0 ? _a : {
            theme: false,
        };
        function setConsent() {
            setCookieConsent(consent);
            router.replace('/');
        }
        console.log(route.query.back);
        return {
            canReturn: route.query.back,
            consent,
            router,
            setConsent,
        };
    },
});
